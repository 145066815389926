import React, { useRef, useEffect, useCallback, useMemo, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from 'styled-components';

const VideoCacheContext = React.createContext({});
const useVideoCache = () => React.useContext(VideoCacheContext);

const SwiperItem = styled.div`
    .swiper-slide__container {
        max-height: ${props => props.theme.scale * props.theme.videoMaxHeight}px;          
        transition: max-height 0.5s;
        overflow: hidden;
        video {
            width: ${props => props.theme.scale * props.theme.videoWidth}px;    
            height: ${props => props.theme.scale * props.theme.videoHeight}px;    
        }
    }
    img {
        width: 100%;
        height: 100%;
    }
`;

const StyledSlickSlider = styled(SlickSlider)`
    &:focus {
        outline: none;
        
    } 
    .slick-slide {
        position: relative;
        width: ${props => props.theme.scale * props.theme.videoWidth}px;
        height: ${props => props.theme.scale * props.theme.videoHeight - 35}px;
        padding-top: ${props => props.theme.scale * 17}px;
        transition: padding 0.5s, opacity 0.5s, height 0.5s;
        overflow: hidden;
        opacity: 0.8;
    }
    .slick-current {
        padding-top: 0;
        opacity: 1;
        height: ${props => props.theme.scale * props.theme.videoHeight}px;
        .swiper-slide__container {            
            max-height: ${props => props.theme.scale * props.theme.videoHeight}px;
        }
    }
`;

const Vid = memo(({ id, src, img, play, onEnded }) => {
    const vidRef = useRef(null);
    const { cache, setCache } = useVideoCache();

    useEffect(() => {
        const videoElement = vidRef.current;
        if (play && !cache[id] && src) {
            videoElement.load();
            setCache(prev => ({ ...prev, [id]: true }));
        }
        
        const attemptPlay = async () => {
            if (play) {
                try {
                    await videoElement.play();
                } catch (error) {
                    console.error("Playback error:", error);
                }
            } else {
                videoElement.pause();
            }
        };
        attemptPlay();
    }, [play, cache, setCache, id, src]);

    return (
        <video
            id={id}
            ref={vidRef}
            preload="auto"
            onEnded={onEnded}
            controls={false}
            poster={img}
            playsInline
            muted={true}
            aria-label="Video content"
        >
            {src && <source src={src} type="video/mp4" />}
        </video>
    );
});

const Slider = ({ className }) => {
    const ads = useSelector(state => state.data.videoads);
    const scale = useSelector(state => state.config.scale);
    const layout = useSelector(state => state.config.layout);
    const mySlider = useRef(null);
    const imageTimer = useRef(null);  // Timer for image slides
    const [cache, setCache] = useState({});
    const [activeSlide, setActiveSlide] = useState(0);

    // Move to the next slide when the video ends
    const handleVideoEnd = useCallback(() => {
        if (mySlider.current) {
            mySlider.current.slickNext();
        }
    }, []);

    // Handle image slide timing and change
    useEffect(() => {
        if (!ads || ads.length === 0) return;

        const ad = ads[activeSlide];

        if (ad?.video) {
            // If it's a video, clear any image timers
            clearTimeout(imageTimer.current);
        } else if (ad?.image) {
            // If it's an image, auto-move to the next slide after 10s
            clearTimeout(imageTimer.current);
            imageTimer.current = setTimeout(() => {
                if (mySlider.current) {
                    mySlider.current.slickNext();
                }
            }, 10000); // 10 seconds delay for images
        }

        return () => clearTimeout(imageTimer.current);
    }, [activeSlide, ads]);

    const params = useMemo(() => ({
        infinite: ads?.length > 1,  // Allow infinite scrolling only if multiple items exist
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,  // Force center mode even with a single item
        centerPadding: `${(layout === 2 ? 72 : 60) * scale}px`,
        dots: ads?.length > 1,
        autoplay: false,  // Disable autoplay
        lazyLoad: 'ondemand',
        beforeChange: (_, next) => setActiveSlide(next),
    }), [ads, layout, scale]);

    // Guard clause for empty or undefined ads
    if (!ads || ads.length === 0) {
        return <div></div>;
    }

    return (
        <VideoCacheContext.Provider value={{ cache, setCache }}>
            <StyledSlickSlider ref={mySlider} className={className} {...params}>
                {ads.map((ad, i) => (
                    <SwiperItem key={i}>
                        <div className="swiper-slide__container">
                            {ad.video ? (
                                <Vid id={`video${i}`} src={ad.video} img={ad.placeholderurl} play={i === activeSlide} onEnded={handleVideoEnd} />
                            ) : (
                                <img src={ad.image} alt={ad.title} />
                            )}
                        </div>
                    </SwiperItem>
                ))}
            </StyledSlickSlider>
        </VideoCacheContext.Provider>
    );
};

export default Slider;
