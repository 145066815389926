import {getActiveLanguage} from "react-localize-redux";


export const getCustomer = (state) => state.config.customer;
export const getSliderOrientation = (state) => state.config.sliderOrientation;
export const getScreen = (state) => state.config.screen;
export const getGroup = (state) => state.config.group;
export const getLocation = (state) => state.config.location;
export const getLanguage = (state) => getActiveLanguage(state.localize).code;
export const getLocal = (state) => state.config.local;
export const getPlayerName = (state) => state.config.playerName;
