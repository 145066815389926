import React, {useEffect} from 'react';
import {Translate} from 'react-localize-redux';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {GridHorizontal} from '../components/Layout';
import SwipeIndicator from '../components/SwipeIndicator';
import images from '../config/images';
import {
    SET_ACTIONBUTTON,
    SET_SWIPEINDICATOR
} from '../redux/actions';

const HomeWrapper = styled.div`
    position: relative;
    transform: translateZ(0);

    ${props => props.theme.layout !== 3 && `
        height: 100%;
    `}

    ${props => props.theme.layout === 3 && `
        overflow-x: scroll;
    `}
`;

const Activity = styled.div`
    flex: 0 0 ${props => props.theme.scale * props.theme.agendaWidth}px;
    width: ${props => props.theme.scale * props.theme.agendaWidth}px;
    height: ${props => props.theme.scale * props.theme.agendaHeight}px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    * {
        box-sizing: border-box;
    }

`;

const ActivityImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${props => props.theme.black};
    text-align: center;
    flex: 0 0 auto;
`;

const ActivityImage = styled.img`
    height: ${props => props.theme.scale * 480}px;
    width: 100%;
    object-fit: cover;
`;

const ActivityContent = styled.div`
    padding: ${props => props.theme.scale * 15}px;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background-color: rgba(255,255,255,0.8);
`;

const ActivityTitle = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-900';
    margin-bottom: ${props => props.theme.scale * 5}px;

    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailTitle = styled.div`
    font-size: ${props => props.theme.scale * 36}px;
    font-family: 'MuseoSans-900';
    margin-bottom: ${props => props.theme.scale * 5}px;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailDescription= styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-300';
    box-sizing: border-box;
    margin-top: ${props => props.theme.scale * 10}px;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${props => props.theme.scale * ((props.theme.contentHeight / 2) - 97)}px;
    left: ${props => props.theme.scale * ((props.theme.contentWidth / 2) - 45)}px;
`;

const Placeholder = styled.div`
    ${props => props.theme.layout !== 3 && `
        height: 100%;
    `}
    
    ${props => props.theme.layout === 3 && `
        height: ${props.theme.scale * 960}px;
    `}
`;

const PlaceholderBackground = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Activities = () => {
    const activities = useSelector(state => state.data.activities);
    const swipeIndicator = useSelector(state => state.config.swipeIndicator);
    const layout = useSelector(state => state.config.layout);
    const navItem = useSelector(state => state.config.navItem);
    const theme = useSelector(state => state.config.theme);
    const mobiliteReduite = useSelector(
        (state) => state.config.mobiliteReduite
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIONBUTTON, actionButton: "copyright"});
    }, [dispatch]);

    if (!activities) return null;

    const hideSwiper = () => { 
        if (swipeIndicator === true) dispatch({ type: SET_SWIPEINDICATOR, state: false});
    }

    
    let placeholder = [];
    if (activities.length < 9) {
        let num = 8 - activities.length;

        for (let index = 0; index < num; index++) {
            placeholder.push(
                <Activity key={index}>
                    <ActivityImageWrapper>
                        <ActivityImage src={images.placeholder.agenda_big} alt="No Activity" />
                    </ActivityImageWrapper>
                    <ActivityContent>
                    <ActivityTitle><Translate id={"activities.noactivities"} /></ActivityTitle>
                    </ActivityContent>
                </Activity>
            );
            
        }
    }

    return (
        <HomeWrapper onMouseDown={hideSwiper} onTouchStart={hideSwiper} onScroll={hideSwiper}>
            {activities.length > 0 ?
                <React.Fragment>
                    <GridHorizontal>
                        {activities.map((activities,index) => (
                            <Activity>
                                <ActivityImageWrapper>
                                    {activities.imageurl && <ActivityImage src={activities.imageurl} alt={activities.title} />}
                                </ActivityImageWrapper>
                                <ActivityContent>
                                    <ActivityTitle>{activities.title}</ActivityTitle>
                                    <DetailDescription dangerouslySetInnerHTML={{__html: activities.description.replace(/\n/g, '<br />')}}></DetailDescription>
                                </ActivityContent>
                            </Activity>
                        ))}
                        {placeholder}
                    </GridHorizontal>
                    {(swipeIndicator && layout === 3 && activities.length > 8) && <SwipeIndicatorWrapper direction="h" />}
                    {(swipeIndicator && layout !== 3 && activities.length > 8) && <SwipeIndicatorWrapper direction="v" />}
                </React.Fragment>
            :
                <Placeholder>
                    <PlaceholderBackground src={images.placeholder.agenda_big} alt={"Placeholder"} />
                </Placeholder>
            }

        </HomeWrapper>
    );
}

export default Activities;