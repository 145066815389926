import {put, select, takeLatest} from 'redux-saga/effects';
import {getApiUrl} from '../../config/helper';
import {getCustomer, getLocal, getScreen, getGroup, getLocation} from '../../selectors';
import {
    CONFIG_RECEIVED,


    pollStart, SET_CUSTOMER,


    SET_DIMENSIONS, SET_ERROR
} from '../actions';


function* fetchConfig() {

    const customer = yield select(getCustomer); 
    const screen = yield select(getScreen); 
    const local = yield select(getLocal); 
    const group =  yield select(getGroup); 
    const location = yield select(getLocation); 
    const apiUrl = getApiUrl();

    if (!customer) yield put({ type: SET_ERROR, error: 2, message: 'CUSTOMER not set!' });
    if (!screen) yield put({ type: SET_ERROR, error: 3, message: 'SCREEN not set!' });

    let screenUrl = `config/config_${customer}_screen${screen}.json`;

    try {
        if(local === "2") {
            let screenUrl = `config/config_${customer}_screen${screen}_${group}_${location}.json`;
            // console.log("Screenurl with group and location : ", screenUrl)
            const json = yield fetch(screenUrl)
            .then(response => response.json()); 
            yield put({ type: CONFIG_RECEIVED, json: json });
            yield put({ type: SET_DIMENSIONS, layout: json.layout });  
            yield put(pollStart());
        }
        else if (local === "1") {
            const uuid = yield fetch('http://localhost:8000/identity')
            .then(response => response.json()); 

            const playerinfo = yield fetch(`${apiUrl}/playerinfo?player=${uuid.identity}`)
            .then(response => response.json()); 

            //const navigation = yield fetch(`${apiUrl}/preset?customer=${location.player.client}&screen=${screen}`)
            //.then(response => response.json()); 

            const navigation = yield fetch(`${apiUrl}/preset?customer=${customer}&screen=${screen}`)
            .then(response => response.json()); 

            const json = {
                location: playerinfo.player.location,
                navigation: navigation.navigation,
                theme: navigation.theme,
                weatherStation: navigation.weatherStation,
                name: playerinfo.player.name
            }

            yield put({ type: CONFIG_RECEIVED, json: json });   
            yield put({ type: SET_DIMENSIONS, layout: navigation.layout });  
            yield put(pollStart());
        } 
        else {
            // console.log("Screenurl without group and location : ", screenUrl)
            const json = yield fetch(screenUrl)
            .then(response => response.json()); 
            yield put({ type: CONFIG_RECEIVED, json: json });
            yield put({ type: SET_DIMENSIONS, layout: json.layout });  
            yield put(pollStart());
        }

        document.title = 'Smart Touch';

    } catch (error) {
        document.title = 'Smart Touch error';
        yield put({ type: SET_ERROR, error: 1, message: 'CUSTOMER/SCREEN does not exist!' });
    }
    
}


function* fetchConfigWatcher() {
    yield takeLatest(SET_CUSTOMER, fetchConfig)
}

export default fetchConfigWatcher;
