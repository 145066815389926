import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {Translate} from 'react-localize-redux';
import SwipeIndicator from '../components/SwipeIndicator';
import SlickSlider from "react-slick";
import {Loading} from "../components/Layout";
import background from "../images/bg_menu.jpg";
import {SET_SWIPEINDICATOR} from "../redux/actions";

const CenteredTitle = styled.h1`
       font-family: 'MuseoSans-500';
       text-transform: uppercase;
       font-size: 28px;
       text-align: center;
       margin: 20px 0;
       color: black;
   `;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${props => props.theme.scale * ((props.theme.contentHeight / 2) - 97)}px;
    left: ${props => props.theme.scale * 400}px;
`;

const SwiperItem = styled.div`
    .swiper-slide__container {
        width: 100%;
        overflow: hidden;
    }
    &:focus {
        outline: none;
    }
`;
const MenuBlock = styled.div`
`;

const MenuDetail = styled.div`
    position: relative;
    transform: translateZ(0);
    height: 1500px;

    margin: 5% 10%;

    h2 {
        font-size: 24px;
        font-family: 'MuseoSans-100';
        text-align: left;
        width: 35%;
        color: ${props => props.theme.shade2}};
    }
    td {
        font-size: 23px;
        font-family: 'MuseoSans-300';
        text-align: left;
        padding-bottom: 3%;
    }
    th {
        padding-top: 5%;
        padding-bottom: 3%;
        font-size: 26px;
        font-family: 'MuseoSans-300';
        text-transform: uppercase;
        text-align: left;
        width: 35%;
        color: ${props => props.theme.shade2}};
    }
`;

const MenuWrapper = styled.div`
    height: 100%;
    overflow-y: auto;
`;

const StyledSlickSlider = styled(SlickSlider)`
    overflow-y: auto;

    &:focus {
        outline: none;
    }
    .slick-list,
    .slick-track {
        height: 100%;
    }
    .slick-slide {
        width: 100%;
        height: 100%;
    }
    .slick-prev {
        top: auto;
        left: ${(props) => props.theme.scale * 360}px;
    }
    .slick-next {
        top: auto;
        right: ${(props) => props.theme.scale * 360}px;
    }
`;

const Restaurant = () => {

    const menu = useSelector(state => state.data.restaurant);
    const swipeIndicator = useSelector(state => state.config.swipeIndicator);
    const theme = useSelector(state => state.config.theme);
    const layout = useSelector((state) => state.config.layout);
    const dispatch = useDispatch();

    let slides = [];
    
    let slidesToShow = 1;
    let slidesToScroll = 1;

    if (layout === 3) {
        slidesToShow = 2;
        slidesToScroll = 2;
    }

    const params = {
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        dots: false,
    };

    if (!menu) return(null);

    const style = {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
    }

    const hideSwiper = () => { 
        if (swipeIndicator === true) dispatch({ type: SET_SWIPEINDICATOR, state: false});
    }

    const menuText = (item, i) => {
        let output = "";
        
        output = (
            <SwiperItem key={i}>
                <div className="swiper-slide__container">
                    <MenuBlock>
                        <MenuDetail>
                            <div>
                            <CenteredTitle dangerouslySetInnerHTML={{__html: item.title.replace(/\n/g, '<br />')}}></CenteredTitle>
                            <table>
                                <tr>
                                    <th>Petit Déjeuner</th>
                                    <td dangerouslySetInnerHTML={{__html: item.breakfast.replace(/\n/g, '<br />')}}></td>
                                </tr>
                                <tr>
                                    <th>Déjeuner</th>
                                </tr>
                                <tr>
                                    <h2>Entrées</h2>
                                    <td dangerouslySetInnerHTML={{__html: item.lunch_starters.replace(/\n/g, '<br />')}}></td>
                                </tr>
                                <tr>
                                    <h2>Plats</h2>
                                    <td dangerouslySetInnerHTML={{__html: item.lunch_main_course.replace(/\n/g, '<br />')}}></td>
                                </tr>
                                <tr>
                                    <h2>Dessert</h2>
                                    <td dangerouslySetInnerHTML={{__html: item.lunch_dessert.replace(/\n/g, '<br />')}}></td>
                                </tr>
                                <tr>
                                    <th>Collation</th>
                                    <td dangerouslySetInnerHTML={{__html: item.snack.replace(/\n/g, '<br />')}}></td>
                                </tr>
                                <tr>
                                    <th>Diner</th>
                                </tr>
                                <tr>
                                    <h2>Potage</h2>
                                    <td dangerouslySetInnerHTML={{__html: item.dinner_soup.replace(/\n/g, '<br />')}}></td>
                                </tr>
                                <tr>
                                    <h2>Plat</h2>
                                    <td dangerouslySetInnerHTML={{__html: item.dinner_main_course.replace(/\n/g, '<br />')}}></td>
                                </tr>
                            </table>
                        </div>
                        </MenuDetail>
                    </MenuBlock>
                </div>
            </SwiperItem>
        );
        return output;
    }

    if (menu) {
        slides = menu.map(
            (item, i) => {
                return menuText(item, i);
            },
            [SwiperItem]
        );
    }

    return (
        <MenuWrapper onTouchStart={hideSwiper} onMouseDown={hideSwiper} style={style}>
            {menu ? (
                <StyledSlickSlider {...params}>{slides}</StyledSlickSlider>
            ) : (
                <Loading />
            )} 
            {swipeIndicator && <SwipeIndicatorWrapper/>}
        </MenuWrapper>
    );
};

export default Restaurant;